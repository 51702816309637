import { Navigate } from 'react-router-dom';

import loadable from '@loadable/component';

import { IRouteProps } from 'shared/consts/types';

const Bots = loadable(() => import('pages/Bots/Bots' /* webpackChunkName: "Bots" */));
const AccountSettings = loadable(() => import('pages/AccountSettings' /* webpackChunkName: "AccountSettings" */));
const NoAccess = loadable(() => import('pages/NoAccess/NoAccess' /* webpackChunkName: "NoAccess" */));
const NoPageFound404 = loadable(
	() => import('pages/NoPageFound404/NoPageFound404' /* webpackChunkName: "NoPageFound404" */)
);
const AccountVerification = loadable(
	() => import('pages/AccountVerification/AccountVerification' /* webpackChunkName: "AccountVerification" */)
);
const verifyAccountPage = loadable(
	() => import('pages/OnBoarding/VerifyAccount' /* webpackChunkName: "VerifyAccountOnBoarding" */)
);
const AccountDetails = loadable(
	() => import('pages/AccountSettings/accountDetails' /* webpackChunkName: "AccountDetails" */)
);
const PassWordSetting = loadable(
	() => import('pages/AccountSettings/passwordSettings' /* webpackChunkName: "Profile" */)
);
const Profile = loadable(() => import('pages/AccountSettings/profile' /* webpackChunkName: "Profile" */));
const Region = loadable(() => import('pages/AccountSettings/region' /* webpackChunkName: "Region" */));
const SecuritySettings = loadable(
	() => import('pages/AccountSettings/securitySettings' /* webpackChunkName: "SecuritySettings" */)
);
const Subscription = loadable(
	() => import('pages/AccountSettings/subscription' /* webpackChunkName: "Subscription" */)
);
const UsageDetails = loadable(
	() => import('pages/AccountSettings/usageDetails' /* webpackChunkName: "UsageDetails" */)
);
const AllCustomerMAU = loadable(
	() => import('pages/AccountSettings/allCustomerMAU' /* webpackChunkName: "AllCustomerMAU" */)
);
const TeamMembers = loadable(() => import('pages/AccountSettings/teamMembers'));
const ThemeSettings = loadable(
	() => import('pages/AccountSettings/themeSettings' /* webpackChunkName: "ThemeSettings" */)
);
const Notification = loadable(
	() => import('pages/AccountSettings/notificationScreen' /* webpackChunkName: "NotificationScreen" */)
);
const PaymentHistory = loadable(
	() => import('pages/AccountSettings/paymentHistory' /* webpackChunkName: "PaymentHistory" */)
);
const BillingPlans = loadable(
	() => import('pages/AccountSettings/BillingPlans' /* webpackChunkName: "BillingPlans" */)
);
const CreditsRechargeHistory = loadable(
	() => import(/* webpackChunkName: "CreditsRechargeHistory" */ 'pages/AccountSettings/creditsRechargeHistory')
);
const CreditsConsumptionHistory = loadable(
	() => import(/* webpackChunkName: "CreditsConsumptioneHistory" */ 'pages/AccountSettings/creditsConsumptionHistory')
);
const WalletStatementHistory = loadable(
	() => import(/* webpackChunkName: "WalletStatementHistory" */ 'pages/AccountSettings/walletStatementHistory')
);
const WalletConsumptionHistory = loadable(
	() => import(/* webpackChunkName: "WalletConsumptionHistory" */ 'pages/AccountSettings/walletConsumptionHistory')
);
const WhatsappPhoneNumber = loadable(
	() => import('pages/OnBoarding/Whatsapp/WAPhoneNumber' /* webpackChunkName: "WhatsappSandboxOnboard" */)
);
const AnalyticsDashboard = loadable(
	() => import('pages/Analytics/Bot/AnalyticsDashboard' /* webpackChunkName: "AnalyticsDashboard" */)
);

export const NoColumnRouteLinks = {
	accountSettings: '/account-settings',
	bots: '/bots',
	billing: '/account-settings/billing',
	paymentHistory: '/account-settings/billing/payment-history',
	billingPlans: '/account-settings/pricing',
	noAccess: '/no-access',
	noPageFound: '/not-found',
	verify: '/verify',
	verifyAccount: '/verify-account',
	creditsRechargeHistory: '/account-settings/usage-details/credits/recharge-history',
	walletStatementHistory: '/account-settings/usage-details/wallet/wallet-statement-history',
	walletConsumptionHistory: '/account-settings/usage-details/wallet/wallet-consumption-history',
	creditsConsumptionHistory: '/account-settings/usage-details/credits/consumption-history',
	usageBreakdown: '/account-settings/usage-breakdown',
	usageDetails: '/account-settings/usage-details',
	whatsappSetup: '/wa-phone',
};

const { accountSettings, bots, billingPlans, noAccess, noPageFound, verify, verifyAccount, whatsappSetup } =
	NoColumnRouteLinks;

export const NoColumnRoutes: Array<IRouteProps> = [
	{
		path: bots,
		component: Bots,
	},
	{
		path: accountSettings,
		component: AccountSettings,
		subRoutes: [
			{
				path: 'profile',
				element: <Profile />,
			},
			{
				path: 'region',
				element: <Region />,
			},
			{
				path: 'theme',
				element: <ThemeSettings />,
			},
			{
				path: 'account',
				element: <AccountDetails />,
			},
			{
				path: 'billing/payment-history',
				element: <PaymentHistory />,
			},
			{
				path: billingPlans,
				element: <BillingPlans />,
			},
			{
				path: 'usage-details',
				element: <UsageDetails />,
			},
			{
				path: 'usage-breakdown',
				element: <AnalyticsDashboard />,
			},
			{
				path: 'usage-details/credits/recharge-history',
				element: <CreditsRechargeHistory />,
			},
			{
				path: 'usage-details/wallet/wallet-statement-history',
				element: <WalletStatementHistory />,
			},
			{
				path: 'usage-details/credits/consumption-history',
				element: <CreditsConsumptionHistory />,
			},
			{
				path: 'usage-details/wallet/wallet-consumption-history',
				element: <WalletConsumptionHistory />,
			},
			{
				path: 'billing',
				element: <Subscription />,
			},
			{
				path: 'security',
				element: <SecuritySettings />,
			},
			{
				path: 'password',
				element: <PassWordSetting />,
			},
			{
				path: 'notification',
				element: <Notification />,
			},
			{
				path: '',
				element: <Navigate to='profile' />,
			},
			{
				path: 'allCustomerMAU',
				element: <AllCustomerMAU />,
			},
			{
				path: 'team-members',
				element: <TeamMembers />,
			},
		],
	},
	{
		path: noAccess,
		component: NoAccess,
	},
	{
		path: noPageFound,
		component: NoPageFound404,
	},
	{
		path: verify,
		component: AccountVerification,
	},
	{
		path: verifyAccount,
		component: verifyAccountPage,
	},
	{
		path: whatsappSetup,
		component: WhatsappPhoneNumber,
	},
];
