import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useTranslation } from 'react-i18next';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { IObjProps } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import PaymentFailureImg from 'shared/icons/PaymentFailure.svg';

function PaymentFailureModalExpertAssistance({
	toggleModal,
	isModalOpen,
	setOpenConfirmationModal,
}: {
	toggleModal: Function;
	setOpenConfirmationModal: Function;
	isModalOpen: boolean;
}) {
	const [css, theme]: [Function, IObjProps] = useStyletron();
	const { t } = useTranslation(['pages', 'common']);
	const { isSmallDesktopScreen } = useResponsiveSize();

	const commonStyles = {
		font: {
			fontSize: '1rem',
			lineHeight: '1.25rem',
			fontWeight: 400,
			textAlign: 'center',
			color: theme.colors.primaryA,
		},
	};

	const styles = {
		header: {
			fontSize: '1.25rem',
			lineHeight: '1.625rem',
			fontWeight: 700,
			marginTop: '1.5rem',
			color: theme.colors.primaryA,
		},
		font1: {
			...commonStyles.font,
			fontWeight: 500,
		},
	};

	const onConfirm = () => {
		toggleModal(false);
		setOpenConfirmationModal(true);
	};

	const onClose = () => {
		toggleModal(false);
	};

	return (
		<>
			<ActionModal
				autoFocus={false}
				isOpen={isModalOpen}
				cancelButtonUsage
				width={isSmallDesktopScreen ? '90vw' : '28rem'}
				oneFooterBtnReq
				confirmBtnLabel={t('components:confirmationModal.retryPayment')}
				onConfirm={onConfirm}
				onClose={onClose}
				onCancelBtnClick={onClose}
			>
				<Block display='flex' flexDirection='column' gridGap='0.5rem' alignItems='center'>
					<ENGTImage src={PaymentFailureImg} />
					<span className={css(styles.header)}>{t('components:confirmationModal.paymentFailed')}</span>
					<span className={css(styles.font1)}>{t('components:confirmationModal.payFailMsg1')}</span>
					<span className={css(commonStyles.font)}>{t('components:confirmationModal.payFailMsg2')}</span>
				</Block>
			</ActionModal>
		</>
	);
}

export default PaymentFailureModalExpertAssistance;
