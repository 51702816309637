import { Suspense, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { formatDate } from 'baseui/datepicker';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { LabelSmall } from 'baseui/typography';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';

import loadable from '@loadable/component';

import { IObjProps } from 'shared/consts/types';
import DatePickerIcon from 'shared/icons/DatePickerIcon.svg';
import DownTriangularArrow from 'shared/icons/DownTriangularArrow';
import LeftArrowIcon from 'shared/icons/LeftArrowIcon';
import RightArrowIcon from 'shared/icons/RightArrowIcon';

import Loader from '../Loader/Loader';

import 'react-datepicker/dist/react-datepicker.css';
import './DefaultDatePicker.scss';

const DatePicker = loadable(() => import('react-datepicker' /* webpackChunkName: "ReactDatePicker" */));

/**
 * @author khushboo
 * Created on: 01/03/2022
 */

export interface IDatePickerProps {
	error?: IObjProps;
	/**
	 * Date has to be in format of iso string like “2021-05-13T00:00:00Z”2021-06-08T18:30:00.000Z
	 * on selecting the value is returned in "Fri May 14 2021 00:00:00 GMT+0530" format.
	 */
	value: Date | any;
	[key: string]: any;
	maxDate?: Date;
	minDate?: Date;
	isDisabled?: boolean;
	dataQa?: string;
	dateFormat?: string;
	inputPlaceholder?: string;
	height?: string;
	isPickerRanged?: boolean;
	startDate?: Date;
	endDate?: Date;
	isNewFilter?: boolean;
	professionalHelpFlow?: boolean;
	onChange: (value: any) => any;
	isDateField?: boolean;
}

const DefaultDatePicker = ({
	error,
	value,
	onChange,
	maxDate = undefined,
	minDate = undefined,
	isDisabled,
	dataQa,
	dateFormat = 'MMM dd, yyyy',
	inputPlaceholder = '',
	popupIndex,
	height,
	isPickerRanged = false,
	startDate = new Date(),
	endDate,
	isNewFilter = false,
	professionalHelpFlow = false,
	isDateField = false,
	...rest
}: IDatePickerProps) => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['components']);

	const applyStylesForDarkMode = () => {
		setTimeout(() => {
			const inRange = document.getElementsByClassName('react-datepicker__day--in-range');
			const inSelectRange = document.getElementsByClassName('react-datepicker__day--in-selecting-range');
			if (theme.colors.themeMode === 'DARK') {
				for (let i = 0; i < inRange?.length; i++) {
					inRange[i].classList.add('react-datepicker__day--in-range-dark');
				}
				for (let i = 0; i < inSelectRange?.length; i++) {
					inSelectRange[i].classList.add('react-datepicker__day--in-range-dark');
				}
			}
		}, 300);
	};

	const errorMsgCss = {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		marginTop: '0.5rem',
		color: theme.inputErrorMsgColor,
	};

	const normalTriggerBoxCss = {
		height: '2.5rem',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		background: theme.colors.inputFillPrimary,
		borderTop: `2px solid ${error ? theme.colors.inputBorderError : `${theme.dividerDarkColor}46`}`,
		borderLeft: `2px solid ${error ? theme.colors.inputBorderError : `${theme.dividerDarkColor}46`}`,
		borderRight: `2px solid ${error ? theme.colors.inputBorderError : `${theme.dividerDarkColor}46`}`,
		borderBottom: `2px solid ${error ? theme.colors.inputBorderError : `${theme.dividerDarkColor}46`}`,
		borderTopLeftRadius: '0.5rem',
		borderTopRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
	};

	const dateFieldCss = {
		...normalTriggerBoxCss,
		border: `1px solid ${error?.message ? theme.colors.inputBorderError : `${theme.colors.accent400}46`}`,
		padding: '0.1rem',
	};

	const pickerTriggerBoxCss = {
		...normalTriggerBoxCss,
		minWidth: '40%',
		height: height || '1.12rem !important',
		paddingTop: '0.5rem',
		paddingLeft: '0.5rem',
		paddingRight: '0.5rem',
		paddingBottom: '0.5rem',
	};

	const dateValueCss = {
		fontSize: '0.8rem',

		display: 'flex',
		alignItems: 'center',
		paddingTop: '0.25rem',
	};

	const selectedDateCss = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: '0.375rem',
		paddingLeft: '0.5rem',
		paddingRight: '0.5rem',
		paddingBottom: '0.375rem',
		background: theme.modalCloseBgColor,
		borderTop: `1px solid ${theme.datePickerInputBorder}`,
		borderLeft: `1px solid ${theme.datePickerInputBorder}`,
		borderRight: `1px solid ${theme.datePickerInputBorder}`,
		borderBottom: `1px solid ${theme.datePickerInputBorder}`,
		borderTopLeftRadius: '0.5rem',
		borderTopRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
	};

	const monthYearLabelsCss = (isSelected: boolean) => ({
		fontSize: '0.813rem',
		cursor: 'pointer',
		paddingTop: '0.438rem',
		paddingLeft: '0.313rem',
		paddingRight: '0.313rem',
		paddingBottom: '0.313rem',
		color: isSelected ? theme.colors.accent : theme.colors.primaryA,
		border: `1px solid transparent`,
		':hover': {
			border: `1px solid ${isSelected ? theme.colors.accent : theme.colors.primaryA}`,
			borderRadius: '9rem',
		},
	});

	const monthYearTriggerBtnCss = (isSelected: boolean) => ({
		paddingTop: '1rem',
		paddingBottom: '1rem',
		display: 'flex',
		justifyContent: 'space-between',
		width: '50%',
		paddingLeft: '1.25rem',
		paddingRight: '1.25rem',
		cursor: 'pointer',
		background: isSelected ? theme.modalCloseBgColor : theme.colors.inputFillPrimary,
		':hover': {
			background: theme.modalCloseBgColor,
		},
	});

	const [initYear, setInitYr] = useState<number>(getYear(new Date()));
	const [showFromPicker, setShowFromPicker] = useState<boolean>(false);
	const [showToPicker, setShowToPicker] = useState<boolean>(false);
	const [years, setYears]: any = useState(range(initYear - 12, initYear + 13, 1));

	const setRangeOfYears = (val: number) => setYears(range(val - 12, val + 13, 1));

	useEffect(() => {
		applyStylesForDarkMode();
		value ? setInitYr(getYear(value)) : setInitYr(getYear(new Date()));
		if (value) {
			setRangeOfYears(getYear(value || new Date()));
		}
	}, []);

	useEffect(() => {
		setRangeOfYears(initYear);
	}, [initYear]);

	const [pickerState, setPickerState] = useState<'month' | 'year' | null>(null);

	useEffect(() => {
		applyStylesForDarkMode();
	}, [showFromPicker, showToPicker, pickerState]);

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const renderMonths = (selectedMonth: number, changeMonth: Function) => {
		const elHeight: any = document.getElementsByClassName('react-datepicker__month-container');
		const ht = elHeight[0]?.offsetHeight || 266;

		return (
			<div
				className={css({
					position: 'absolute',
					top: '3rem',
					width: 'calc(100% - 20px)',
					paddingLeft: '0.625rem',
					paddingRight: '0.625rem',
					height: `${ht - 60}px`,
					background: theme.modalCloseBgColor,
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr',
					gridTemplateRows: '1fr 1fr 1fr 1fr',
					columnGap: '0.313rem',
					rowGap: '0.313rem',
					paddingTop: '0.313rem',
					paddingBottom: '0.313rem',
					justifyItems: 'center',
					alignItems: 'center',
				})}
			>
				{months.map((month: string, index: number) => (
					<div onClick={() => changeMonth(index)}>
						<LabelSmall className={css(monthYearLabelsCss(selectedMonth === index))}>{month}</LabelSmall>
					</div>
				))}
			</div>
		);
	};

	const renderYears = (selectedYear: any, changeYear: Function) => {
		const elHeight: any = document.getElementsByClassName('react-datepicker__month-container');
		const ht = elHeight[0]?.offsetHeight || 266;

		return (
			<div
				className={css({
					position: 'absolute',
					top: '3rem',
					width: 'calc(100% - 20px)',
					paddingLeft: '0.625rem',
					paddingRight: '0.625rem',
					height: `${ht - 50}px`,
					background: theme.modalCloseBgColor,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-around',
				})}
			>
				<div className={css({ padding: '1rem', display: 'flex', justifyContent: 'space-evenly' })}>
					<span
						className={css({
							display: 'flex',
							alignItems: 'center',
							cursor: initYear - 25 > getYear(new Date()) - 50 ? 'pointer' : 'not-allowed',
						})}
						onClick={() => initYear - 25 > getYear(new Date()) - 50 && setInitYr(initYear - 25)}
					>
						<LeftArrowIcon fillColor={theme.colors.primaryA} height='0.5rem' width='0.5rem' />
					</span>
					<LabelSmall
						className={css({
							fontSize: '0.813rem',
						})}
					>
						{t('components:datePickerCustom.selectYear')}
					</LabelSmall>
					<span
						onClick={() => initYear + 25 < getYear(new Date()) + 50 && setInitYr(initYear + 25)}
						className={css({
							display: 'flex',
							alignItems: 'center',
							cursor: initYear + 25 < getYear(new Date()) + 50 ? 'pointer' : 'not-allowed',
						})}
					>
						<RightArrowIcon fillColor={theme.colors.primaryA} height='0.5rem' width='0.5rem' />
					</span>
				</div>
				<div
					className={css({
						display: 'grid',
						gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
						gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
						columnGap: '0.313rem',
						rowGap: '0.188rem',
						paddingLeft: '0.5rem',
						paddingRight: '0.5rem',
						paddingBottom: '0.5rem',
						overflow: 'hidden',
					})}
				>
					{years.map((year: number, index: number) => (
						<div onClick={() => changeYear(year)}>
							<LabelSmall className={css(monthYearLabelsCss(selectedYear === year))}>{year}</LabelSmall>
						</div>
					))}
				</div>
			</div>
		);
	};

	// <TODO> validate date on month and year change
	const renderPickerContent = (props?: any) => {
		const { close }: any = props || {};

		return (
			<div
				className={css({
					paddingTop: isNewFilter ? '0rem' : '1rem',
					paddingLeft: '1rem',
					paddingRight: '1rem',
					paddingBottom: '0.5rem',
					background: theme.colors.inputFillPrimary,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				})}
			>
				{!isNewFilter && (
					<div
						className={css({
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							marginBottom: '1rem',
						})}
					>
						<LabelSmall
							className={css({
								fontSize: '0.813rem',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							})}
						>
							{t('components:datePickerCustom.selectDate')}
						</LabelSmall>
						<div className={css(selectedDateCss)}>
							<LabelSmall
								className={css({ ...dateValueCss, marginLeft: '0.375rem', marginRight: '1.531rem' })}
							>
								{value ? formatDate(value, dateFormat) : t('components:datePickerCustom.addDate')}
							</LabelSmall>
							<img src={DatePickerIcon} alt='date-picker' />
						</div>
					</div>
				)}
				<div className={professionalHelpFlow ? 'customDatePickerWidth' : ''}>
					<Suspense fallback={<Loader />}>
						<DatePicker
							useWeekdaysShort
							dateFormat={dateFormat}
							inline
							dayClassName={() =>
								theme.colors.themeMode === 'LIGHT'
									? 'react-datepicker__day-light'
									: 'react-datepicker__day-dark'
							}
							calendarClassName={
								theme.colors.themeMode === 'LIGHT'
									? 'react-datepicker__light-calendar'
									: 'react-datepicker__dark-calendar'
							}
							weekDayClassName={() =>
								theme.colors.themeMode === 'LIGHT'
									? 'react-datepicker__light-weekday'
									: 'react-datepicker__dark-weekday'
							}
							maxDate={maxDate}
							minDate={minDate}
							renderCustomHeader={({ date, changeYear, changeMonth }) => (
								<div className={css({ display: 'flex', flexDirection: 'column' })}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											background: theme.colors.inputFillPrimary,
										}}
									>
										<div
											onClick={() =>
												pickerState != 'month' ? setPickerState('month') : setPickerState(null)
											}
											className={css(monthYearTriggerBtnCss(pickerState === 'month'))}
										>
											<LabelSmall>{months[getMonth(date)]}</LabelSmall>
											<div className={css({ display: 'flex', alignItems: 'center' })}>
												<DownTriangularArrow fill={theme.colors.primaryA} />
											</div>
										</div>
										<div
											onClick={() => {
												if (pickerState != 'year') {
													value ? setInitYr(getYear(value)) : setInitYr(getYear(new Date()));
													setPickerState('year');
												} else {
													setPickerState(null);
												}
											}}
											className={css(monthYearTriggerBtnCss(pickerState === 'year'))}
										>
											<LabelSmall>{getYear(date)}</LabelSmall>
											<div className={css({ display: 'flex', alignItems: 'center' })}>
												<DownTriangularArrow fill={theme.colors.primaryA} />
											</div>
										</div>
									</div>
									{pickerState && (
										<>
											{pickerState === 'month'
												? renderMonths(getMonth(date), changeMonth)
												: renderYears(getYear(date), changeYear)}
										</>
									)}
								</div>
							)}
							selected={value}
							shouldCloseOnSelect={false}
							onMonthChange={(d: any) => {
								setPickerState(null);
							}}
							onYearChange={(d: any) => {
								setPickerState(null);
							}}
							onChange={(d: any) => {
								onChange(d);
								!isNewFilter && close();
							}}
							{...rest}
						/>
					</Suspense>
				</div>
			</div>
		);
	};

	const selectedDateComponent = (date: any, label: string, placeholder: string) => (
		<div className={css({ display: 'flex', flexDirection: 'column', width: '45%' })}>
			<LabelSmall
				className={css({
					marginLeft: '0.375rem',
					fontSize: '0.813rem',
					display: 'flex',
					width: '80%',
					alignItems: 'center',
					paddingTop: '0.25rem',
				})}
			>
				{label}
			</LabelSmall>
			<div className={css(selectedDateCss)}>
				<LabelSmall
					className={css({
						...dateValueCss,
						width: '80%',
					})}
				>
					{date ? formatDate(date, dateFormat) : placeholder}
				</LabelSmall>
				<img src={DatePickerIcon} alt='date-picker' />
			</div>
		</div>
	);

	const renderRangedPicker = () => (
		<div
			className={css({
				paddingTop: '0.5rem',
				paddingBottom: '0.5rem',
				background: theme.colors.inputFillPrimary,
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				borderTop: `1px solid ${theme.dividerDarkColor}46`,
				borderLeft: `1px solid ${theme.dividerDarkColor}46`,
				borderRight: `1px solid ${theme.dividerDarkColor}46`,
				borderBottom: `1px solid ${theme.dividerDarkColor}46`,
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
			})}
		>
			<div
				className={css({
					display: 'flex',
					justifyContent: 'space-evenly',
					width: '100%',
					marginBottom: '1rem',
				})}
			>
				{selectedDateComponent(value, t('common:labels.from'), t('components:datePickerCustom.fromDate'))}
				{selectedDateComponent(endDate, t('common:labels.to'), t('components:datePickerCustom.toDate'))}
			</div>
			<div
				className={css({ width: '100%', display: 'flex', justifyContent: 'center' })}
				onMouseMove={() => theme.colors.themeMode === 'DARK' && value && !endDate && applyStylesForDarkMode()}
			>
				<Suspense fallback={<Loader />}>
					<DatePicker
						useWeekdaysShort
						onSelect={applyStylesForDarkMode}
						dateFormat={dateFormat}
						inline
						selectsRange
						dayClassName={() =>
							theme.colors.themeMode === 'LIGHT'
								? 'react-datepicker__day-light'
								: 'react-datepicker__day-dark'
						}
						calendarClassName={
							theme.colors.themeMode === 'LIGHT'
								? 'react-datepicker__light-calendar'
								: 'react-datepicker__dark-calendar'
						}
						weekDayClassName={() =>
							theme.colors.themeMode === 'LIGHT'
								? 'react-datepicker__light-weekday'
								: 'react-datepicker__dark-weekday'
						}
						maxDate={maxDate}
						minDate={minDate}
						renderCustomHeader={({ date, changeYear, changeMonth }) => (
							<div className={css({ display: 'flex', flexDirection: 'column' })}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										background: theme.colors.inputFillPrimary,
									}}
								>
									<div
										onClick={() =>
											pickerState != 'month' ? setPickerState('month') : setPickerState(null)
										}
										className={css(monthYearTriggerBtnCss(pickerState === 'month'))}
									>
										<LabelSmall>{months[getMonth(date)]}</LabelSmall>
										<div className={css({ display: 'flex', alignItems: 'center' })}>
											<DownTriangularArrow fill={theme.colors.primaryA} />
										</div>
									</div>
									<div
										onClick={() => {
											if (pickerState != 'year') {
												value ? setInitYr(getYear(value)) : setInitYr(getYear(new Date()));
												setPickerState('year');
											} else {
												setPickerState(null);
											}
										}}
										className={css(monthYearTriggerBtnCss(pickerState === 'year'))}
									>
										<LabelSmall>{getYear(date)}</LabelSmall>
										<div className={css({ display: 'flex', alignItems: 'center' })}>
											<DownTriangularArrow fill={theme.colors.primaryA} />
										</div>
									</div>
								</div>
								{pickerState && (
									<>
										{pickerState === 'month'
											? renderMonths(getMonth(date), changeMonth)
											: renderYears(getYear(date), changeYear)}
									</>
								)}
							</div>
						)}
						selected={value}
						startDate={value}
						endDate={endDate}
						shouldCloseOnSelect={false}
						onMonthChange={(d: Date) => {
							setPickerState(null);
						}}
						onYearChange={(d: Date) => {
							setPickerState(null);
						}}
						onChange={(d: any) => {
							onChange(d);
							if (d?.[0] && d?.[1]) {
								setShowToPicker(false);
								setShowFromPicker(false);
							}
						}}
					/>
				</Suspense>
			</div>
		</div>
	);

	return (
		<>
			{isPickerRanged ? (
				<div
					data-qa={dataQa}
					className={css({
						width: '100%',
						opacity: isDisabled ? '0.8' : '1',
						pointerEvents: isDisabled ? 'none' : '',
					})}
				>
					{(showFromPicker && !showToPicker) || (showToPicker && !showFromPicker) ? (
						renderRangedPicker()
					) : (
						<div
							className={css({
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
							})}
						>
							<div
								onClick={() => {
									setShowFromPicker(!showFromPicker);
									setShowToPicker(false);
								}}
								className={css(pickerTriggerBoxCss)}
							>
								<LabelSmall className={css(dateValueCss)}>
									{value ? (
										formatDate(value, dateFormat)
									) : (
										<LabelSmall color='#6e6e6e'>
											{inputPlaceholder || t('components:datePickerCustom.fromDate')}
										</LabelSmall>
									)}
								</LabelSmall>
								<img src={DatePickerIcon} alt='date-picker' />
							</div>
							<div
								onClick={() => {
									setShowToPicker(!showToPicker);
									setShowFromPicker(false);
								}}
								className={css(pickerTriggerBoxCss)}
							>
								<LabelSmall className={css(dateValueCss)}>
									{endDate ? (
										formatDate(endDate, dateFormat)
									) : (
										<LabelSmall color='#6e6e6e'>
											{inputPlaceholder || t('components:datePickerCustom.toDate')}
										</LabelSmall>
									)}
								</LabelSmall>
								<img src={DatePickerIcon} alt='date-picker' />
							</div>
						</div>
					)}
				</div>
			) : !isNewFilter ? (
				<StatefulPopover
					onClose={() => setPickerState(null)}
					content={renderPickerContent}
					placement={PLACEMENT.bottomLeft}
					overrides={{
						Body: {
							style: ({ $theme }: any) => ({
								zIndex: popupIndex,
							}),
						},
					}}
				>
					<div
						data-qa={dataQa}
						className={css({
							opacity: isDisabled ? '0.8' : '1',
							pointerEvents: isDisabled ? 'none' : '',
						})}
					>
						<div className={css(isDateField ? dateFieldCss : normalTriggerBoxCss)}>
							<LabelSmall
								className={css({
									marginLeft: '1rem',
									fontSize: '0.8rem',
									width: '80%',
									display: 'flex',
									alignItems: 'center',
									overflow: 'hidden',
									paddingTop: '0.25rem',
								})}
							>
								{value ? (
									formatDate(value, dateFormat)
								) : (
									<LabelSmall color='#6e6e6e'>
										{inputPlaceholder || t('components:datePickerCustom.addDate')}
									</LabelSmall>
								)}
							</LabelSmall>
							<img className={css({ marginRight: '1rem' })} src={DatePickerIcon} alt='date-picker' />
						</div>
					</div>
				</StatefulPopover>
			) : (
				<div
					data-qa={dataQa}
					className={css({
						width: '100%',
						opacity: isDisabled ? '0.8' : '1',
						pointerEvents: isDisabled ? 'none' : '',
					})}
				>
					{renderPickerContent()}
				</div>
			)}
			{error?.message && <div className={css(errorMsgCss)}>{error.message}</div>}
		</>
	);
};

export default DefaultDatePicker;
