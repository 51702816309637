import React from 'react';
import { mergeOverrides, useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { Modal as BaseModal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { PLACEMENT } from 'baseui/tooltip';
import { useTranslation } from 'react-i18next';

import Button from 'components/UI/ENGTButton/ENGTButton';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';
import Loader from 'components/UI/Loader/Loader';

import { IObjProps } from 'shared/consts/types';
import { hexToRgb, isEmpty } from 'shared/helpers';

export interface IModalProps {
	autoFocus?: boolean;
	children?: any;
	closeBtnLabel?: string;
	confirmBtnLabel?: any;
	description?: string | React.ReactNode;
	descriptionCss?: {};
	overridHeaderCss?: {};
	heading?: string | React.ReactNode;
	height?: string;
	hideCloseBtn?: boolean;
	isCancelBtnDisabled?: boolean;
	isConfirmBtnDisabled?: boolean;
	isFooterReq?: boolean;
	oneFooterBtnReq?: boolean;
	isLoading?: boolean;
	isOpen: boolean;
	inputType?: string;
	usage?: 'bot' | 'default' | 'nodeCreation' | 'campaignCreation';
	width?: string;
	modalBodyCss?: {};
	dataQa?: string;
	modalOverrides?: {};
	footerOverrideCss?: IObjProps;
	buttonOverrides?: IObjProps;
	cancelButtonUsage?: boolean;
	showPopover?: boolean;
	popOverText?: string;

	onClose: (args?: any) => any;
	onConfirm: (args?: any) => any;
	onCancelBtnClick?: (args?: any) => any;
	onModalClose?: () => any | undefined;
}

const modalFooterCss = (isFooterReq: boolean | undefined, footerOverrideCss?: IObjProps) => ({
	display: 'flex',
	textAlign: 'center !important' as 'center',
	paddingTop: isFooterReq ? '12px' : '0',
	marginLeft: '0 !important',
	marginRight: '0 !important',
	paddingBottom: '0 !important',
	// justifyContent: 'center',
	...footerOverrideCss,
});

const modalLoaderCss = (theme: any) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	background: theme.colors.primaryA,
	width: '100%',
	opacity: '0.48 !important',
	height: '100%',
	borderRadius: '1rem',
	zIndex: theme.zIndex400,
});

const modalOverrideProps = ({
	height,
	width,
	hideCloseBtn,
	theme,
	modalOverrides = {},
}: {
	height?: string;
	width?: string;
	hideCloseBtn?: boolean;
	theme: any;
	modalOverrides?: any;
}) =>
	mergeOverrides(
		{
			Root: {
				style: {
					zIndex: theme.zIndex300,
				},
			},
			Close: {
				style: {
					display: hideCloseBtn ? 'none' : 'block',
					borderRadius: '50%',
					height: '48px',
					width: '48px',
					background: hexToRgb(theme.modalCloseBgColor, 0.5),
					color: `${theme.modalCloseStrokeColor} !important`,
				},
			},
			DialogContainer: {
				style: () => ({
					backgroundColor: hexToRgb(theme.colors.modalOverlayColor, 0.7),
				}),
			},
			Dialog: {
				style: {
					backgroundColor: theme.modalPrimaryBackground,
					borderTopRightRadius: '1rem',
					borderTopLeftRadius: '1rem',
					borderBottomLeftRadius: '1rem',
					borderBottomRightRadius: '1rem',
					paddingRight: '2rem',
					paddingLeft: '2rem',
					paddingTop: '2rem',
					paddingBottom: '2rem',
					width,
					height,
				},
			},
		},
		modalOverrides
	);

function ActionModal(props: IModalProps) {
	const { t } = useTranslation(['common']);
	const {
		autoFocus = true,
		children,
		closeBtnLabel = t('common:cancel'),
		confirmBtnLabel = t('common:confirm'),
		description,
		descriptionCss,
		heading,
		overridHeaderCss,
		height,
		hideCloseBtn,
		isFooterReq,
		oneFooterBtnReq,
		inputType,
		isOpen,
		isCancelBtnDisabled,
		isConfirmBtnDisabled,
		isLoading,
		width,
		usage = 'default',
		modalBodyCss,
		dataQa,
		onClose,
		onConfirm,
		onModalClose,
		onCancelBtnClick,
		modalOverrides,
		footerOverrideCss,
		buttonOverrides,
		cancelButtonUsage,
		showPopover = false,
		popOverText = '',
	} = props;
	const [css, theme]: [any, any] = useStyletron();

	const ModalLoader = () =>
		isLoading ? (
			<div className={css(modalLoaderCss(theme))}>
				<Loader loaderBlockHeight='100%' />
			</div>
		) : (
			<></>
		);
	const config: IObjProps = {
		bot: {
			marginTop: '0 !important',
			fontSize: '2rem !important',
		},
		default: {
			marginTop: '0 !important',
			fontSize: '1.25rem !important',
		},
		nodeCreation: {
			marginTop: '0 !important',
			fontSize: '1.25rem !important',
		},
		campaignCreation: {
			marginTop: '0 !important',
			fontSize: '1.25rem !important',
		},
	};

	const modalHeaderCss = ($theme: any) => ({
		color: $theme.modalTextColor,
		fontWeight: '700 !important',
		marginTop: config[usage].marginTop,
		fontSize: usage === 'default' ? '1.25rem !important' : '1.75rem !important',
		lineHeight: usage === 'default' ? '1.625rem !important' : '2.5rem !important',
		marginBottom: '0 !important',
		marginLeft: '0 !important',
		marginRight: '0 !important',
	});

	const bodyCss = {
		marginLeft: '0 !important',
		marginRight: '0 !important',
		color: theme.modalTextColor,
		fontSize: '1rem',
		lineHeight: '1.25rem',
		fontWeight: '400',
	};

	const confirmBtn = () => (
		<Button
			kind={KIND['primary']}
			size={SIZE.large}
			label={confirmBtnLabel}
			className={css({ width: '100%', ...buttonOverrides?.confirmBtnCss })}
			isDisabled={isConfirmBtnDisabled}
			onClick={onConfirm}
			dataQa={`primary-${dataQa}`}
		/>
	);

	return (
		<>
			<BaseModal
				unstable_ModalBackdropScroll
				autoFocus={autoFocus}
				onClose={onModalClose || onClose}
				isOpen={isOpen}
				animate
				overrides={modalOverrideProps({ height, width, hideCloseBtn, theme, modalOverrides })}
			>
				<ModalLoader />
				<ModalHeader className={css(!isEmpty(overridHeaderCss) ? overridHeaderCss : modalHeaderCss(theme))}>
					{heading}
				</ModalHeader>
				<ModalBody className={css(modalBodyCss || bodyCss)}>
					{description && <p className={css(descriptionCss)}>{description}</p>}

					{children}
				</ModalBody>

				<ModalFooter className={css(modalFooterCss(isFooterReq, footerOverrideCss))}>
					{isFooterReq && (
						<>
							{!oneFooterBtnReq && (
								<Button
									kind={KIND['secondary']}
									size={SIZE.large}
									onClick={cancelButtonUsage ? onCancelBtnClick : onClose}
									label={closeBtnLabel}
									isDisabled={isCancelBtnDisabled}
									className={css({
										marginRight: '15px !important',
										width: '100%',
										...buttonOverrides?.closeBtnCss,
									})}
									dataQa={`secondary-${dataQa}`}
								/>
							)}
							{showPopover ? (
								<ENGTTooltip ignoreBoundary content={popOverText} placement={PLACEMENT.top}>
									<div className={css({ width: '100%', ...buttonOverrides?.confirmBtnCss })}>
										{confirmBtn()}
									</div>
								</ENGTTooltip>
							) : (
								<>{confirmBtn()}</>
							)}
						</>
					)}
				</ModalFooter>
			</BaseModal>
		</>
	);
}

ActionModal.defaultProps = {
	autoFocus: true,
	descriptionCss: {},
	overridHeaderCss: {},
	footerOverrideCss: {},
	isFooterReq: true,
	oneFooterBtnReq: false,
	isLoading: false,
	width: '23rem',
};

export default ActionModal;
