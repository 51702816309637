import { ACCOUNT_TYPES, AGENDAS, SUPPORT_OPTIONS } from 'shared/consts/consts';

import { IMessageType } from '../components/ExpertAssistanceModal';

export const setAdminModeInLs = () => {
	localStorage.removeItem('storeDataVersion');
	localStorage.removeItem('storeData');
	localStorage.setItem('adminMode', 'false');
	window.location.href = '/';
};

export const isCurrentUserNotAdmin = (email: string) =>
	localStorage.getItem('loggedInUsercontext') && localStorage.getItem('loggedInUsercontext') !== email;

export const getAdminPartnerMode = (isReseller: boolean, accountType: string, email: string) => {
	const { MASTER_ADMIN, RESELLER, AFFILIATE } = ACCOUNT_TYPES;
	let adminMode = false;
	let isPartner = !!isCurrentUserNotAdmin(email);

	if (isReseller && [MASTER_ADMIN, RESELLER, AFFILIATE].indexOf(accountType) > -1) {
		isPartner = true;

		if (localStorage.getItem('adminMode') !== null) {
			adminMode = localStorage.getItem('adminMode') === 'true';
		} else {
			adminMode = true;
		}
		localStorage.setItem('adminMode', `${adminMode}`);
	}

	return [adminMode, isPartner];
};
export const getAgendaType = (selectedTypes: IMessageType[]) => {
	let agenda = '';

	selectedTypes.forEach((type) => {
		switch (type.id) {
			case SUPPORT_OPTIONS.WHATSAPP_CAMPAIGNS:
				agenda = AGENDAS.RUN_A_WHATSAPP_CAMPAIGN;
				break;
			case SUPPORT_OPTIONS.ONBOARDING_NUMBER:
				agenda = AGENDAS.INTEGRATE_THIRD_PARTY_SYSTEM;
				break;
			case SUPPORT_OPTIONS.AUTOMATED_WORKFLOWS:
				agenda = AGENDAS.CUSTOMISE_EXISTING_AUTOMATION_FLOWS;
				break;
			case SUPPORT_OPTIONS.PLATFORM_TRAINING:
				agenda = AGENDAS.PLATFORM_TRAINING;
				break;
		}
	});

	return agenda;
};
