import { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE as ButtonSize } from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { EXPERT_ASSISTANCE_PARAM } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import PaymentSuccessImg from 'shared/icons/PaymentSuccessful.svg';

import { RootState } from 'store/rootReducer';
import { customerIdSelector } from 'store/Users/selectors';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

function PaymentSuccessModalExpertAssistance({
	toggleModal,
	isModalOpen,
	dateValue,
}: {
	toggleModal: Function;
	isModalOpen: boolean;
	dateValue: Date;
}) {
	const [css, theme]: [Function, IObjProps] = useStyletron();
	const navigate = useNavigate();
	const { t } = useTranslation(['pages', 'common']);
	const cid = useSelector(customerIdSelector);

	const [searchParams, setSearchParams] = useSearchParams();
	const timezone = useSelector((state: RootState) => state.User.profile.data.timezone);
	const { isSmallDesktopScreen } = useResponsiveSize();
	const storedDateString = localStorage.getItem(`${cid}-dateValue`);

	const retrievedDate = storedDateString ? new Date(storedDateString) : null;

	useEffect(() => {
		if (searchParams.get(EXPERT_ASSISTANCE_PARAM) === 'true') {
			navigate('', { replace: true });
			searchParams.delete(EXPERT_ASSISTANCE_PARAM);
			setSearchParams(searchParams);
		}
	}, [searchParams]);

	const commonStyles = {
		font: {
			fontSize: '1rem',
			lineHeight: '1.25rem',
			fontWeight: 400,
			textAlign: 'center',
			marginBottom: '1rem',
			color: theme.colors.primaryA,
		},
	};

	const styles = {
		header: {
			fontSize: '1.25rem',
			lineHeight: '1.625rem',
			fontWeight: 700,
			marginTop: '1.5rem',
			marginBottom: '1rem',
			color: theme.colors.primaryA,
		},
		font1: {
			...commonStyles.font,
			fontWeight: 500,
		},
		button: {
			marginBottom: '1rem',
		},
	};

	const onConfirm = () => {
		toggleModal(false);
		navigate(TwoColumnRouteLinks.waQuickNavigator);
	};

	const onClose = () => {
		toggleModal(false);
	};

	return (
		<>
			<ActionModal
				autoFocus={false}
				isOpen={isModalOpen}
				cancelButtonUsage
				width={isSmallDesktopScreen ? '90vw' : '28rem'}
				isFooterReq={false}
				onConfirm={onConfirm}
				onClose={onClose}
				onCancelBtnClick={onClose}
			>
				<Block display='flex' flexDirection='column' gridGap='0.5rem' alignItems='center'>
					<ENGTImage src={PaymentSuccessImg} />
					<span className={css(styles.header)}>{t('components:confirmationModal.paymentSuccessful')}</span>
					<span className={css(styles.font1)}>{t('components:confirmationModal.paySuccessMsg1')}</span>
					<div className={css(styles.button)}>
						<ENGTButton
							label={t('pages:accountSettings.subscriptionDetailsPage.viewInvoices')}
							kind={KIND['primary']}
							size={ButtonSize.default}
							type='submit'
							onClick={() => {
								navigate(NoColumnRouteLinks.billing);
							}}
						/>
					</div>
					<span className={css(commonStyles.font)}>
						{t('components:confirmationModal.confirmedSlot')}{' '}
						{`${t('common:standardDateTimeFormatterWithShortMonthName', {
							date: retrievedDate || dateValue,
						})} (${timezone})`}
					</span>
				</Block>
			</ActionModal>
		</>
	);
}

export default PaymentSuccessModalExpertAssistance;
