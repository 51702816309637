import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import ENGTTimePicker from 'components/UI/ENGTTimePicker/ENGTTimePicker';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { TIME_SLOTS_WORKING_HOURS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { timezoneMapper } from 'shared/helpers';

import { API } from 'store/AccountSettings/api';
import { fontStyleSelector } from 'store/App/User';

type DateTimeModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	onModalClose: () => void;
	dateValue: Date;
	setDateValue: (date: Date) => void;
};

const updateDateWithTime = (date: Date, time: string) => {
	const timeParts = time.split('_');
	date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), 0, 0);

	return date;
};

const DateTimeModal = ({ isOpen, onClose, onConfirm, onModalClose, dateValue, setDateValue }: DateTimeModalProps) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components', 'pages', 'common']);

	const [allTimezones, setAllTimezones] = useState<{ id: string; label: string }[]>([]);
	const [timeValue, setTime] = useState<string>(TIME_SLOTS_WORKING_HOURS[0].id);
	const [timezone, setTimezone] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(false);
	const fontStyle: string = useSelector(fontStyleSelector);

	const methods = useFormContext();
	const { control, setValue } = methods;

	const timezoneSetter = () => {
		const timezoneMap: IObjProps = timezoneMapper();

		return { id: timezoneMap[timezone], label: timezoneMap[timezone] };
	};
	const minDateValue = new Date();
	const regionDetails: IObjProps = {
		selectedTimezone: '',
		preview: '',
	};

	const noProfileDataErrorToaster = () =>
		toaster.negative(
			<ENGTToasterContainer
				title={t('pages:accountSettings.region.unsuccessful')}
				description={t('pages:accountSettings.region.noProfileDataFetched')}
			/>,
			{}
		);

	const noTimezonesErrorToaster = () =>
		toaster.negative(
			<ENGTToasterContainer
				title={t('pages:accountSettings.region.unsuccessful')}
				description={t('pages:accountSettings.region.noProfileDataFetched')}
			/>,
			{}
		);

	useEffect(() => {
		Object.keys(regionDetails).map((key) => setValue(key, regionDetails[key]));
		setLoading(true);

		API.profileDetails()
			.then((resp: IObjProps) => {
				if (resp.data) {
					if (!resp.data.timezone || resp.data.timezone === 'UTC') {
						setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
					} else {
						setTimezone(resp.data.timezone);
					}
				} else {
					noProfileDataErrorToaster();
				}
				setLoading(false);
			})
			.catch(() => {
				noProfileDataErrorToaster();
				setLoading(false);
			});

		API.getAllTimezones()
			.then((resp: IObjProps) => {
				if (resp.data && resp.data.responseObject) {
					setAllTimezones(
						resp.data.responseObject.map((value: IObjProps) => ({
							id: value.timezoneIdentifier,
							label: value.timezoneName,
						}))
					);
				} else {
					noTimezonesErrorToaster();
				}
			})
			.catch(() => {
				noTimezonesErrorToaster();
			});
	}, []);

	return (
		<ActionModal
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onConfirm}
			onModalClose={onModalClose}
			isConfirmBtnDisabled={isLoading}
			closeBtnLabel={t('common:back')}
			heading={t('components:dateTimeModal.book')}
			overridHeaderCss={{
				color: theme.colors.modalTextColor,
				fontWeight: '700 !important',
				fontSize: '1.25rem !important',
				lineHeight: '1.25rem !important',
				marginLeft: '0rem !important',
				marginRight: '0rem !important',
				marginTop: '0rem !important',
				marginBottom: '0.5rem !important',
			}}
		>
			<div
				className={css({
					display: 'flex',
					alignContent: 'space-around !important',
					flexDirection: 'column',
				})}
			>
				<FormLabel
					id='choose-date'
					label={t('components:dateTimeModal.date')}
					className={{ marginBottom: '0.25rem', fontSize: '0.875rem', fontWeight: 500 }}
				/>
				<DefaultDatePicker
					value={dateValue}
					onChange={(val: Date) => {
						setDateValue(updateDateWithTime(val, timeValue));
					}}
					minDate={minDateValue}
					isNewFilter
					filterDate={(date: any) => {
						const day = date.getDay();

						return day !== 0 && day !== 6;
					}}
					professionalHelpFlow
				/>
				<Controller
					control={control}
					name='selectedTimezone'
					defaultValue={timezoneSetter()}
					render={({ ref, value, onChange }) => (
						<FormSelect
							name='selectedTimezone'
							label={t('components:dateTimeModal.timezone')}
							inputRef={ref}
							onChange={(value) => {
								value?.[0]?.id && setTimezone(value[0].id);
								onChange(value);
							}}
							options={allTimezones}
							clearable
							selectedValue={value}
							width='100%'
							labelClassName={{
								fontFamily: fontStyle,
							}}
							searchable
							popupIndex={100}
						/>
					)}
				/>

				<FormLabel
					id='feedback-type'
					label={t('components:dateTimeModal.time')}
					className={{ marginBottom: '0.25rem', fontSize: '0.875rem', fontWeight: 500 }}
				/>
				<ENGTTimePicker
					value={timeValue}
					options={TIME_SLOTS_WORKING_HOURS}
					onChange={(val) => {
						if (val) {
							const updatedDate = updateDateWithTime(dateValue, val);
							setDateValue(updatedDate);
							setTime(val);
						}
					}}
					popupIndex={theme.zIndex400}
					placeholder={t('common:selectTime')}
				/>
			</div>
		</ActionModal>
	);
};

export default DateTimeModal;
